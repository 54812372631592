import React, { useRef } from "react";
import { graphql, useStaticQuery, HeadProps, PageProps } from "gatsby";
// Components
import Layout from "components/Layout";
import { Page, PageSidebar, PageHeader, PageTitle } from "components/Page";
import HTMLContent from "components/HTMLContent";
import HeadTags from "components/Head";
// Types
import { MarkdownContent } from "types/MarkdownRemark";
import { ArticleContext } from "types/PageContext";

type DataProps = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
  markdownRemark: MarkdownContent;
};

// markup
const HomePage = ({
  data: { site, markdownRemark },
}: PageProps<DataProps, ArticleContext>) => {
  const { frontmatter, html } = markdownRemark;
  const { id, slug, title } = frontmatter;
  const htmlContentRef = useRef<HTMLDivElement>(null);
  return (
    <Layout title={title} slug={slug}>
      <Page id={id}>
        <PageHeader>
          <PageTitle>{title}</PageTitle>
        </PageHeader>
        <HTMLContent ref={htmlContentRef}>{html}</HTMLContent>
      </Page>
      <PageSidebar contentRef={htmlContentRef} />
    </Layout>
  );
};

export const Head = (props: HeadProps<DataProps>) => (
  <HeadTags
    {...props}
    pageContext={{
      ...props.data.markdownRemark.frontmatter,
      type: "website",
    }}
  />
);

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { slug: { eq: "home" } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;

export default HomePage;
